<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="action-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isActionNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'Aksiyon Ekle' : 'Aksiyon Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="Durum" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.ideaStatusId" :options="actionStatusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Açıklama" rules="required">
            <b-form-group label="Açıklama">
              <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="10"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">Kaydet</b-button>
            <b-button type="button" variant="outline-secondary" @click="$emit('update:is-action-new-sidebar-active', false)">Vazgeç</b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";

export default {
  components: {
    BFormFile,
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isActionNewSidebarActive',
    event: 'update:is-action-new-sidebar-active',
  },
  props: {
    isActionNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      ideaStatusId: null,
      description: '',
    }

    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const actionStatusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'IDEA_STATUS_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.key !== 'NEW' && value.key !== 'CANCELLED') {
          actionStatusOptions.value.push({label: value.name, value: value.key})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editAction' : 'addAction'), {
        ideaId: router.currentRoute.params.id,
        dataInfo: dataInfo.value
      }).then(response => {
        toastMessage(toast, 'success', response.data.message)
        emit('update:is-action-new-sidebar-active', false)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isActionNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
        } else {
          busy.value = true
          store.dispatch('store/fetchAction', {ideaId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-action-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      actionStatusOptions,

      getValidationState,
      resetForm,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#action-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
