<template>
  <b-card>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="MessageSquareIcon"/>
          <span>Geri Bildirim Bilgileri</span>
        </template>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12" md="5" lg="5">
                <b-row>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Geri Bildirim Türü" rules="required">
                      <b-form-group label="Geri Bildirim Türü" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.ideaTypeId" :options="ideaTypeOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Kategori" rules="required">
                      <b-form-group label="Kategori" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.ideaCategoryId" :options="categoryOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Öncelik" rules="required">
                      <b-form-group label="Öncelik" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.priorityStatusId" :options="priorityStatusOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12" v-if="!($router.currentRoute.params.id > 0)">
                    <validation-provider #default="validationContext" name="Döküman Ekle">
                      <b-form-group label="Döküman Ekle">
                        <b-form-file id="ideaFiles" name="ideaFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12" v-if="$router.currentRoute.params.id > 0">
                    <validation-provider #default="validationContext" name="Durum" rules="required">
                      <b-form-group label="Durum" :state="getValidationState(validationContext)">
                        <v-select disabled="disabled" v-model="dataInfo.ideaStatusId" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="7" lg="7">
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="İçerik" rules="required">
                    <b-form-group label="İçerik">
                      <b-form-textarea trim placeholder="İçerik" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="11"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-col>
            </b-row>
            <action-buttons :back-route="'idea-list'"/>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab v-if="$router.currentRoute.params.id > 0">
        <template #title>
          <feather-icon icon="FileIcon"/>
          <span>Döküman Bilgileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="dataInfo.documents"
              :fields="documentFields"
              class="mb-0"
              striped responsive bordered hover>

            <template #cell(filePath)="data">
              <div class="text-nowrap">
                <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewFile(data.value)" class="mx-1"/>
              </div>
            </template>

            <template #cell(createdOn)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

          </b-table>
        </b-card>
      </b-tab>
      <ActionList :idea-user-id="dataInfo.userId" :idea-status-id="dataInfo.ideaStatusId" v-if="$router.currentRoute.params.id > 0 && dataInfo.id > 0"/>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BTable, BFormTextarea, BButton, BTooltip, BFormFile
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils"
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/idea/idea/store"
import definitionModule from "@/views/system/definition/store"
import categoryModule from "@/views/idea/category/store"
import flatpickr from "vue-flatpickr-component/src/component"
import {getApiFile, yesNoOptions} from "@core/utils/filter"
import {DateTimeColumnTable} from "@/components/Table";
import ActionList from "@/views/idea/idea/action/List.vue"

export default {
  components: {
    DateTimeColumnTable,
    BFormFile,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BTable,
    BFormTextarea,
    BButton,
    BTooltip,
    flatpickr,

    ActionButtons,
    ActionList,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      ideaTypeId: null,
      ideaCategoryId: null,
      priorityStatusId: null,
      ideaStatusId: null,
      description: ''
    })

    const ideaTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'IDEA_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        ideaTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && dataInfo.value.id === 0) {
          dataInfo.value.ideaTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const priorityStatusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'PRIORITY_STATUS'}).then(response => {
      response.data.data.forEach((value, index) => {
        priorityStatusOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && dataInfo.value.id === 0) {
          dataInfo.value.priorityStatusId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const statusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'IDEA_STATUS_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        statusOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const categoryOptions = ref([])
    busy.value = true
    store.dispatch('store-category/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          categoryOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        if (!(router.currentRoute.params.id > 0) && document.getElementById('ideaFiles').files.length > 0) {
          const documents = document.getElementById('ideaFiles').files
          const formData = new FormData();
          for (let i = 0; i < documents.length; i++) {
            let file = documents[i];
            formData.append('documents[' + i + ']', file);
          }

          store.dispatch('store/addDocument', {ideaId: response.data.data, dataInfo: formData}).then(response => {
            toastMessage(toast, 'success', response.data.message)
            router.push({name: 'idea-list'})
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
          }).finally(message => {
            busy.value = false
          })

        } else {
          toastMessage(toast, 'success', response.data.message)
          router.push({name: 'idea-list'})
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'idea-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const documentFields = [
      {key: 'name', label: 'İsim', sortable: false},
      {key: 'createdUserName', label: 'Kayıt Sahibi', sortable: false},
      {key: 'createdOn', label: 'Tarih', sortable: true},
      {key: 'filePath', label: 'Görüntüle', sortable: false},
    ]

    const previewFile = (filePath) => {
      window.open(getApiFile(filePath), '_blank');
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      ideaTypeOptions,
      priorityStatusOptions,
      categoryOptions,
      documentFields,
      statusOptions,

      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      previewFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>