import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/ideas', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ideas/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ideas/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/ideas', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/ideas', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/ideas/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/ideas/cancel', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActionList(ctx, {ideaId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ideaactions/${ideaId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewAction(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ideaactions/${ideaId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAction(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ideaactions/${ideaId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAction(ctx, {ideaId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/ideaactions/${ideaId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editAction(ctx, {ideaId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/ideaactions/${ideaId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeAction(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/ideaactions/${ideaId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelAction(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/ideaactions/cancel/${ideaId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDocumentList(ctx, {ideaId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintdocuments/${ideaId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewDocument(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintdocuments/${ideaId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocument(ctx, {ideaId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/ideas/document-add/${ideaId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeDocument(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercomplaintdocuments/${ideaId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelDocument(ctx, {ideaId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintdocuments/cancel/${ideaId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActionDocumentList(ctx, {customerComplaintActionId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/customercomplaintactiondocuments/${customerComplaintActionId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeActionDocument(ctx, {customerComplaintActionId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/customercomplaintactiondocuments/${customerComplaintActionId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelActionDocument(ctx, {customerComplaintActionId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/customercomplaintactiondocuments/cancel/${customerComplaintActionId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
