<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <table class="mt-2 mt-xl-0 w-100">
      <tr class="border-bottom">
        <th class="pb-50"><span class="font-weight-bold">Tarih</span></th>
        <td class="pb-50">
          <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
        </td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Kullanıcı</span></th>
        <td class="py-50">{{ previewData.userFullName }}</td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
        <td class="py-50"><span v-html="previewData.description"></span></td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Durum</span></th>
        <td class="py-50"><span v-html="previewData.ideaStatusName"></span></td>
      </tr>
      <tr>
        <th class="pt-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
        <td class="pt-50">
          <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
        </td>
      </tr>
    </table>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="closeModal">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>
    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BBadge, BButton, BDropdown, BDropdownItem, BLink, BModal, BRow, BTable, BTooltip} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";
import router from "@/router";
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue";
import {getApiFile} from "@core/utils/filter"

export default {
  components: {
    BLink, BTooltip, BTable, BooleanColumnTable, BDropdown, BDropdownItem,
    DateTimeColumnTable,
    ActiveColumnTable,
    Overlay,

    BRow,
    BButton,
    BModal,
    BBadge,
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});
    const previewDocumentData = ref([]);

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined && props.isPreviewModal) {
        busy.value = true
        store.dispatch('store/previewAction', {
          ideaId: router.currentRoute.params.id,
          id: dataValue.id
        }).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const closeModal = () => {
      emit('update:is-preview-modal', false)
      emit('update:data-record', {})
    }

    return {
      busy,
      previewData,
      previewDocumentData,

      recordStatusFormat,
      recordStatusVariant,
      closeModal,
      getApiFile,
    }
  },
}
</script>
