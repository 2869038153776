<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="MessageCircleIcon"/>
      <span>Aksiyon Bilgileri</span>
    </template>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
              <ActionDetail :is-action-new-sidebar-active.sync="isActionNewSidebarActive" :record-data-id="actionRecordDataId"></ActionDetail>
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                  <b-button @click="isActionNewSidebarActive = true; actionRecordDataId=0" variant="primary" size="md" v-if="newRecordVisible">
                    <feather-icon icon="PlusCircleIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(id)="data">
          <b-link @click="changeAction(data.item)" class="font-weight-bold">#{{ data.value }}</b-link>
        </template>

        <template #cell(createdOn)="data">
          <date-time-column-table :data="data.value"></date-time-column-table>
        </template>

        <template #cell(userProfilePicture)="data">
          <div class="text-nowrap">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar size="32" :src="getApiFile(data.value)" :text="avatarText(data.item.userFullName)" :variant="`light-primary`"/>
              </template>
              <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.userFullName }}
          </span>
              <small class="text-muted">{{ data.item.userPositionName }}</small>
            </b-media>
          </div>
        </template>

        <template #cell(ideaStatusName)="data">
          <b-badge pill :variant="`light-${recordIdeaStatusVariant(data.item)}`" class="text-capitalize">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <b-dropdown-item @click="changeAction(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">Değiştir</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeAction(data.item)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Çıkar</span>
              </b-dropdown-item>
            </b-dropdown>
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
            <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <footer-table :data-meta="dataMeta"></footer-table>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <Overlay :busy="busy"></Overlay>
      <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
    </b-card>
  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BBadge, BDropdown, BDropdownItem, BMedia, BAvatar} from 'bootstrap-vue'
import {ref, watch} from '@vue/composition-api'
import useList from './useList'
import {FooterTable, DateTimeColumnTable} from '@/components/Table';
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/idea/idea/action/Preview.vue";
import ActionDetail from "@/views/idea/idea/action/Detail.vue";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import {avatarText, getApiFile, recordIdeaStatusVariant, recordPriorityStatusVariant} from "@core/utils/filter"

export default {
  name: "ActionList",
  components: {
    BAvatar, BMedia,
    BDropdownItem,
    BDropdown,
    BBadge,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, ActionDetail,
    Overlay, Preview, FooterTable, DateTimeColumnTable, vSelect
  },
  props: {
    ideaUserId: {
      type: Number,
      required: true,
    },
    ideaStatusId: {
      type: String,
      required: true,
    },
  },
  setup(props, {emit}) {
    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const isActionNewSidebarActive = ref(false)
    const actionRecordDataId = ref(0)

    const changeAction = (dataItem) => {
      actionRecordDataId.value = dataItem.id
      isActionNewSidebarActive.value = true
    }

    const removeAction = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeAction', {ideaId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt çıkarıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const cancelAction = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt iptal edilecektir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, İptal',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/cancelAction', {ideaId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt iptal edildi.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    watch(isActionNewSidebarActive, (dataValue) => {
      if (dataValue === false) {
        reFetchData()
      }
    })

    const newRecordVisible = ref(true)

    if (JSON.parse(localStorage.getItem('userData')).id === props.ideaUserId || props.ideaStatusId === 'APPROVED' || props.ideaStatusId === 'DENIED') {
      newRecordVisible.value = false
    }

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isPreviewModal,
      dataPreviewRecord,
      isVisible,
      isActionNewSidebarActive,
      actionRecordDataId,
      newRecordVisible,

      fetchList,
      reFetchData,
      previewRecord,

      changeAction,
      removeAction,
      cancelAction,

      avatarText, getApiFile, recordIdeaStatusVariant, recordPriorityStatusVariant
    }
  }
}
</script>